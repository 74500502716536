import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '@/shared/classes/form/group'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'

@Component
export default class AbstractGroup extends Vue {
  @Prop() group!: FormGroup
  @Prop() form!: FormBase

  getFormFieldsByGroup(group: FormGroup): Field[] {
    return this.form.fields.filter((field: Field) => field.group === group.key)
  }
}
