import SimpleGroup from '@/shared/components/form/group-types/SimpleGroup.vue'
import AccordionGroup from '@/shared/components/form/group-types/AccordionGroup.vue'

export enum FormGroupTypes {
  simple = 'simple',
  accordion = 'accordion'
}

export const formGroupTypesMap = {
  [FormGroupTypes.simple]: SimpleGroup,
  [FormGroupTypes.accordion]: AccordionGroup,
}

export default class FormGroup {
  type: FormGroupTypes = FormGroupTypes.simple
  key!: string
  title!: string
  children: FormGroup[] = []
  component!: any
  footer!: any
  componentMeta!: any
  appendToTitle!: any

  setType(type: FormGroupTypes): this {
    this.type = type
    return this
  }

  setKey(key: string): this {
    this.key = key
    return this
  }

  setTitle(title: string): this {
    this.title = title
    return this
  }

  setChildren(children: FormGroup[]): this {
    this.children = children
    return this
  }

  setComponent(component: any): this {
    this.component = component
    return this
  }

  setFooter(footer: any): this {
    this.footer = footer
    return this
  }

  setComponentMeta(componentMeta: any): this {
    this.componentMeta = componentMeta
    return this
  }

  setAppendToTitle(component: any): this {
    this.appendToTitle = component
    return this
  }
}
