

























import { Component } from 'vue-property-decorator'
import AbstractGroup from '@/shared/components/form/group-types/abstract-group'
import FormField from '@/shared/components/form/FormField.vue'

@Component({
  components: {
    FormField,
    Group: () => import('@/shared/components/form/Group.vue'),
  }
})
export default class AccordionGroup extends AbstractGroup {
  expanded: boolean = false
}
