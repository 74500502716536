



import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup, { FormGroupTypes, formGroupTypesMap } from '@/shared/classes/form/group'
import FormBase from '@/shared/classes/form/form-base'
import FormField from '@/shared/components/form/FormField.vue'
import SimpleGroup from '@/shared/components/form/group-types/SimpleGroup.vue'
import AccordionGroup from '@/shared/components/form/group-types/AccordionGroup.vue'

@Component({
  components: { AccordionGroup, SimpleGroup, FormField, Group }
})
export default class Group extends Vue {
  @Prop() group!: FormGroup
  @Prop() form!: FormBase
  types = FormGroupTypes

  get component(): any {
    return formGroupTypesMap[this.group.type]
  }
}
