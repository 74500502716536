

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { LOCALE_ERROR, LOCALES } from '@/shared/constants/locales.constants'
import FormBase from '@/shared/classes/form/form-base'
import { getAdditionalLanguagesByVersion, getLanguagesByVersion } from "@/config";

@Component
export default class LanguageChange extends Vue {
  @Prop() form!: FormBase
  @Prop() exclude!: string[]
  @Prop() noBottomMargin!: boolean

  getFlag(item: string): string {
    return LOCALES[item].flag
  }

  getCode(item: string): string {
    return LOCALES[item].code
  }

  hasErrorItem(locale: string): boolean {
    const found = Object.keys(this.form.errors).find((key: string) => key === `${ LOCALE_ERROR }.${ locale }`)
    return !! found && this.form.errors[found] === true
  }

  get value() {
    return this.items.findIndex((key: string) => this.form.currentLanguage === key)
  }

  set value(value: any) {
    this.form.setCurrentLanguage(this.items[value])
  }

  get items(): any[] {
    if (this.form.additionalTranslatableLanguages) return getAdditionalLanguagesByVersion(this.exclude)
    return getLanguagesByVersion(this.exclude)
  }

  get hasError(): boolean {
    return Object.keys(this.form.errors).some((key: string) => key.startsWith(LOCALE_ERROR))
  }
}
